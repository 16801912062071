<template>
  <div class="checkbox-group">
    <a-radio-group :defaultValue="defaultValue" @change="change" :options="rlist" />
  </div>
</template>

<script>
export default {
  name: 'ChoiceEdit',
  props: {
    option: {
      required: true,
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  methods:{
    change(e){
      this.$emit('pushOn',e.target.value)
    }
  },
  data(){
    return{
      rlist:[],
      defaultValue:null
    }
  },
  created(){
    if (this.option?.research_answer?.the_key) {
      this.defaultValue = +this.option?.research_answer.the_key
    }
    this.rlist=this.option.research_question_option.map(d=>({
      label:d.the_value,
      value:d.the_key,
    }))
  }
}
</script>

<style scoped>
.checkbox-group{
  line-height: normal;
  padding: 6px 0;
}
</style>
