<template>
  <div class="checkbox-group">
    <div style="display: flex">
      <div
          class="handle-box"
          v-for="(d,findex) in 11"
          :key="findex"
          @click="handelNps(findex)"
          :style="{
                      background:npsSelect>=findex?`rgba(0, 165, 120, ${d/10})`:'',
                      color:npsSelect>=findex?`#fff`:'#000'
                    }">
        <div class="nps-box">{{ findex }}</div>
      </div>
    </div>
    <div class="nps-bottom">
      <span>非常不推荐</span>
      <span>非常推荐</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true,
      type: Object,
      default () {
        return {
        }
      }
    }
  },
  created(){
    if (this.option?.research_answer?.the_key) {
      this.npsSelect = +this.option?.research_answer.the_key
    }
  },
  name: "Nps",
  data() {
    return {
      npsSelect: -1
    }
  },
  methods: {
    handelNps(d) {
      this.npsSelect = d
      this.$emit('pushOn',d)
    }
  }
}
</script>

<style scoped lang="less">
.handle-box{
  flex: 1;
  display: flex;
  justify-content: center;
  border: 1px solid #D8D8D8;
}
.nps-bottom{
  line-height: normal;
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
  color: #9A9A9A ;
}
.checkbox-group{
  padding: 6px 0;
}
</style>
